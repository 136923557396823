.ant-modal {
  padding: 0 !important;
}
.ant-modal > .ant-modal-content > .ant-modal-body {
  padding: 0 !important;
}
#highCholesterolReactButtonInner:hover {
  border-color: #FFAC00 !important;
  background-color: #FD3970 !important;
  color: white !important;
}
